

import { computed, ref , reactive} from 'vue';
import {EmailDetail} from "@/models/emailDetail";
import {VideoDetail} from "@/models/videoDetail";
import EvaluationRepository from "@/services/EvaluationRepository";
import { useRouter } from 'vue-router';
import VuePdfEmbed from 'vue-pdf-embed';
import {FilePrice} from "@/models/filePrice";
import authRepository from "@/services/AuthRepository";

export default {
  components: {
    VuePdfEmbed,
  },
  setup(){
   const price = ref({} as FilePrice);
   const link = ref("");

      authRepository.getDoctor()
          .then((response) => {
            price.value = response.data;
            link.value = price.value.name_file;
          })
          .catch(err => {
          });


    return {
      link
    }
  }
}

