import Repository from './Repository'
import { AxiosResponse } from 'axios'
import { Login } from '../models/login'
import { TokenModel } from '../models/token.model'
import {Register} from "@/models/register";
import PasswordModel from "@/models/password.model";
import {UserPer} from "@/models/userPermission.model";

const resource = '/auth'

export default new class {
  auth (login: Login): Promise<AxiosResponse<TokenModel>> {
    console.log('############################## loginpage auth api')
    return Repository.post<TokenModel>(`${resource}/login`, login)
  }
  userPermission(login: Login): Promise<AxiosResponse<UserPer>>{
    return Repository.post<UserPer>(`${resource}/permission`, login);
  }
  registerUser(register: Register): Promise<any>{
    return Repository.post<any>(`${resource}/register`, register);
  }
  changePassword(changePassword: PasswordModel): Promise<any>{
    return Repository.post<TokenModel>(`${resource}/password`, changePassword);
  }
  listEval(kind: number):  Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listEval/${kind}`);
  }
  patientInfo(mayte: string): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getInfoPatient/${mayte}`);
  }
  getServicePrice(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getprice`);
  }
  getMedicalExamination(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getprocess`);
  }
  getSurvey(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getsurvey`);
  }
  getDoctor(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getdoctor`);
  }
}
