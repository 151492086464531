import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/logo.png'


const _hoisted_1 = { class: "p-fluid" }
const _hoisted_2 = { class: "p-fluid p-formgrid p-grid" }
const _hoisted_3 = { class: "p-field p-col p-col-12 p-md-12 p-lg-12" }
const _hoisted_4 = { class: "p-field p-col p-col-12 p-md-6 p-lg-6" }
const _hoisted_5 = { style: {"color":"white","font-size":"30px","font-weight":"bolder"} }
const _hoisted_6 = { class: "p-field p-col p-col-12 p-md-6 p-lg-6" }
const _hoisted_7 = { style: {"color":"white","font-size":"30px","font-weight":"bolder"} }
const _hoisted_8 = { class: "p-field p-col p-col-12 p-md-6 p-lg-6" }
const _hoisted_9 = { style: {"color":"white","font-size":"30px","font-weight":"bolder","align-text":"center"} }
const _hoisted_10 = { class: "p-field p-col p-col-12 p-md-6 p-lg-6" }
const _hoisted_11 = { style: {"color":"white","font-size":"30px","font-weight":"bolder"} }
const _hoisted_12 = { class: "p-field p-col p-col-12 p-md-6 p-lg-6" }
const _hoisted_13 = { style: {"color":"white","font-size":"30px","font-weight":"bolder"} }
const _hoisted_14 = { class: "p-field p-col p-col-12 p-md-6 p-lg-6" }
const _hoisted_15 = { style: {"color":"white","font-size":"30px","font-weight":"bolder"} }
const _hoisted_16 = { class: "p-field p-col p-col-6 p-md-6 p-lg-6" }
const _hoisted_17 = { style: {"color":"white","font-size":"30px","font-weight":"bolder"} }
const _hoisted_18 = { class: "p-field p-col p-col-6 p-md-6 p-lg-6" }
const _hoisted_19 = { style: {"color":"white","font-size":"30px","font-weight":"bolder"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[9] || (_cache[9] = _createElementVNode("div", {
          class: "p-field p-col p-col-12 p-md-12 p-lg-12",
          id: "scroll-container"
        }, [
          _createElementVNode("div", {
            id: "scroll-text",
            style: {"align-items":"center"}
          }, [
            _createElementVNode("img", {
              class: "background",
              src: _imports_0,
              style: {"width":"70px","height":"70px"}
            }),
            _createElementVNode("br"),
            _createElementVNode("label", { style: {"color":"#3e9cba","font-size":"40px"} }, " BỆNH VIỆN Y HỌC CỔ TRUYỀN THÀNH PHỐ ĐÀ NẴNG ")
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Button, {
            class: "p-button-outlined p-button-secondary",
            style: {"height":"12vh","width":"70vw","background":"#c9c9c9","color":"#3e9cba","font-size":"30px","display":"table-cell","vertical-align":"middle"}
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode(" XIN NHẤN TRỰC TIẾP VÀO MÀN HÌNH CẢM ỨNG ĐỂ SỬ DỤNG "),
              _createElementVNode("br", null, null, -1),
              _createTextVNode(" NẾU CÓ RẮC RỐI XIN LIÊN HỆ SĐT : "),
              _createElementVNode("label", { style: {"color":"#c95a71","font-weight":"bold"} }, " 0905-658-333 ", -1)
            ])),
            _: 1
          })
        ]),
        _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Button, {
            class: "p-button-outlined p-button-secondary",
            style: {"height":"12vh","width":"45vw","background":"#89af48","display":"table-cell","vertical-align":"middle"},
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.clickButton($setup.list[1].value)))
          }, {
            default: _withCtx(() => [
              _createElementVNode("label", _hoisted_5, _toDisplayString($setup.list[1].label), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_Button, {
            class: "p-button-outlined p-button-secondary",
            style: {"height":"12vh","width":"45vw","background":"#3f6caf","display":"table-cell","vertical-align":"middle"},
            onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.clickButton($setup.list[2].value)))
          }, {
            default: _withCtx(() => [
              _createElementVNode("label", _hoisted_7, _toDisplayString($setup.list[2].label), 1)
            ]),
            _: 1
          })
        ]),
        _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_Button, {
            class: "p-button-outlined p-button-secondary",
            style: {"height":"12vh","width":"45vw","background":"#fdbdbd","display":"table-cell","vertical-align":"middle"},
            onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.clickButton($setup.list[3].value)))
          }, {
            default: _withCtx(() => [
              _createElementVNode("label", _hoisted_9, _toDisplayString($setup.list[3].label), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_Button, {
            class: "p-button-outlined p-button-secondary",
            style: {"height":"12vh","width":"45vw","background":"#3e9cba","display":"table-cell","vertical-align":"middle"},
            onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.clickButton($setup.list[4].value)))
          }, {
            default: _withCtx(() => [
              _createElementVNode("label", _hoisted_11, _toDisplayString($setup.list[4].label), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_Button, {
            class: "p-button-outlined p-button-secondary",
            style: {"height":"12vh","width":"45vw","background":"#ba8dc9","display":"table-cell","vertical-align":"middle"},
            onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.clickButton($setup.list[5].value)))
          }, {
            default: _withCtx(() => [
              _createElementVNode("label", _hoisted_13, _toDisplayString($setup.list[5].label), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_Button, {
            class: "p-button-outlined p-button-secondary",
            style: {"height":"12vh","width":"45vw","background":"#f79647","display":"table-cell","vertical-align":"middle"},
            onClick: _cache[5] || (_cache[5] = ($event: any) => ($setup.clickButton($setup.list[6].value)))
          }, {
            default: _withCtx(() => [
              _createElementVNode("label", _hoisted_15, _toDisplayString($setup.list[6].label), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createVNode(_component_Button, {
            class: "p-button-outlined p-button-secondary",
            style: {"height":"12vh","width":"45vw","background":"#f84055","display":"table-cell","vertical-align":"middle"},
            onClick: _cache[6] || (_cache[6] = ($event: any) => ($setup.clickButton($setup.list[7].value)))
          }, {
            default: _withCtx(() => [
              _createElementVNode("label", _hoisted_17, _toDisplayString($setup.list[7].label), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createVNode(_component_Button, {
            class: "p-button-outlined p-button-secondary",
            style: {"height":"12vh","width":"45vw","background":"#1ad39c","display":"table-cell","vertical-align":"middle"},
            onClick: _cache[7] || (_cache[7] = ($event: any) => ($setup.clickButton($setup.list[8].value)))
          }, {
            default: _withCtx(() => [
              _createElementVNode("label", _hoisted_19, _toDisplayString($setup.list[8].label), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _cache[12] || (_cache[12] = _createStaticVNode("<div class=\"footer\"><b style=\"alignment:center;color:dimgray;\">Bệnh viện Y Học Cổ Truyền Đà Nẵng</b><br><b style=\"alignment:center;color:dimgray;\">Cơ sở 1: Đường Đinh Gia Trinh, Phường Hòa Xuân, Quận Cẩm Lệ, Thành phố Đà Nẵng</b><br><b style=\"alignment:center;color:dimgray;\">Cơ sở 2: 51 Trần Quốc Toản, Phường Phước Ninh, Quận Hải Châu, Thành phố Đà Nẵng</b><br><b style=\"alignment:center;color:dimgray;\">Điện thoại: 0905.658.333 - Email: benhvienyhoccotruyendn@gmail.com - Website: benhvienyhoccotruyendanang.vn</b></div>", 1))
  ], 64))
}