import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-fluid p-formgrid p-grid" }
const _hoisted_2 = { class: "p-field p-col p-col-12 p-md-4 p-lg-4" }
const _hoisted_3 = { class: "p-field p-col p-col-12 p-md-4 p-lg-4" }
const _hoisted_4 = { class: "p-field p-col p-col-12 p-md-4 p-lg-4" }
const _hoisted_5 = { class: "p-fluid p-formgrid p-grid" }
const _hoisted_6 = { class: "p-field p-col p-col-12 p-md-4 p-lg-4" }
const _hoisted_7 = { class: "p-field p-col p-col-12 p-md-4 p-lg-4" }
const _hoisted_8 = { class: "p-field p-col p-col-12 p-md-4 p-lg-4" }
const _hoisted_9 = { class: "p-field p-col p-col-12 p-md-4 p-lg-4" }
const _hoisted_10 = { class: "p-field p-col p-col-12 p-md-4 p-lg-4" }
const _hoisted_11 = { class: "p-field p-col p-col-12 p-md-4 p-lg-4" }
const _hoisted_12 = { class: "p-field p-col p-col-12 p-md-4 p-lg-4" }
const _hoisted_13 = { id: "loading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Panel = _resolveComponent("Panel")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Panel, { header: "Tìm kiếm bệnh nhân" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[11] || (_cache[11] = _createElementVNode("label", null, "CMND/CCCD", -1)),
            _createVNode(_component_InputText, {
              id: "mayte",
              type: "text",
              modelValue: $setup.mayte,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.mayte) = $event)),
              onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => ($setup.searchInfo()), ["enter"]))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _cache[12] || (_cache[12] = _createElementVNode("label", null, "Tìm kiếm", -1)),
            _createVNode(_component_Button, {
              label: "Tìm thông tin",
              icon: "pi pi-plus-circle",
              iconPos: "left",
              onClick: $setup.searchInfo
            }, null, 8, ["onClick"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _cache[13] || (_cache[13] = _createElementVNode("label", null, "Chọn ngày khám", -1)),
            _createVNode(_component_Dropdown, {
              id: "team",
              modelValue: $setup.ngaykham,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.ngaykham) = $event)),
              options: $setup.thoigian,
              optionLabel: "ngaykham",
              optionValue: "ngaykham",
              placeholder: "Vui lòng lựa chọn ngày khám...",
              onChange: $setup.selectDate
            }, null, 8, ["modelValue", "options", "onChange"])
          ]),
          _cache[14] || (_cache[14] = _createElementVNode("div", { class: "p-field p-col p-col-12 p-md-12 p-lg-12" }, null, -1))
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_Panel, { header: "Thông tin khám bệnh của người bệnh" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[15] || (_cache[15] = _createElementVNode("label", null, "Tên người bệnh", -1)),
            _createVNode(_component_InputText, {
              id: "username",
              type: "text",
              modelValue: $setup.patient.ten_benh_nhan,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.patient.ten_benh_nhan) = $event)),
              readonly: ""
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[16] || (_cache[16] = _createElementVNode("label", null, "Địa chỉ liên lạc:", -1)),
            _createVNode(_component_InputText, {
              id: "diachilienlac",
              type: "text",
              modelValue: $setup.patient.dia_chi_lien_lac,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.patient.dia_chi_lien_lac) = $event)),
              readonly: ""
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _cache[17] || (_cache[17] = _createElementVNode("label", null, "Năm sinh:", -1)),
            _createVNode(_component_InputText, {
              id: "namsinh",
              type: "text",
              modelValue: $setup.patient.nam_sinh,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.patient.nam_sinh) = $event)),
              readonly: ""
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[18] || (_cache[18] = _createElementVNode("label", null, "Số điện thoại:", -1)),
            _createVNode(_component_InputText, {
              id: "dienthoai",
              type: "text",
              modelValue: $setup.patient.so_dien_thoai,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.patient.so_dien_thoai) = $event)),
              readonly: ""
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _cache[19] || (_cache[19] = _createElementVNode("label", null, "Chuẩn đoán bệnh", -1)),
            _createVNode(_component_InputText, {
              id: "chuandoan",
              type: "text",
              modelValue: $setup.patient.chan_doan_khoa_kham,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.patient.chan_doan_khoa_kham) = $event)),
              readonly: ""
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _cache[20] || (_cache[20] = _createElementVNode("label", null, "Triệu chứng lâm sàng", -1)),
            _createVNode(_component_InputText, {
              id: "trieuchung",
              type: "text",
              modelValue: $setup.patient.trieu_chung_lam_sang,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.patient.trieu_chung_lam_sang) = $event)),
              readonly: ""
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _cache[21] || (_cache[21] = _createElementVNode("label", null, "Ngày khám:", -1)),
            _createVNode(_component_InputText, {
              id: "thoigian",
              type: "text",
              modelValue: $setup.patient.ngay_kham,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.patient.ngay_kham) = $event)),
              readonly: ""
            }, null, 8, ["modelValue"])
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_Dialog, {
      header: "Đang tra cứu thông tin",
      visible: $setup.loadingBar,
      "onUpdate:visible": _cache[10] || (_cache[10] = ($event: any) => (($setup.loadingBar) = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_13, [
          _cache[22] || (_cache[22] = _createElementVNode("label", null, "Đang xử lý ...", -1)),
          _createVNode(_component_ProgressBar, {
            mode: "indeterminate",
            style: {"height":".3em"}
          })
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}